/* TODO(ds): Revisit globals */

:root {
  /* TODO(ds): Move into vanilla-extract theme */
  --theme-color-accent: oklch(52.15% 0.2 270.83);
  --theme-color-info: oklch(0.73 0.1 271.53);
  --theme-color-warning: oklch(0.74 0.18 33.71);
  --theme-color-danger: oklch(62.8% 0.26 29.23);
  --theme-color-success: oklch(0.82 0.16 148.51);
  --theme-color-bodyGradientStart: hsl(0, 0%, 94%);
  --theme-color-bodyGradientStop: hsl(32, 1%, 88%);
  --theme-color-mainGradientStartOpacity: 0;
  --theme-color-mainGradientStopOpacity: 0;
  --theme-color-sidebarGradientStartOpacity: 0.8;
  --theme-color-sidebarGradientStopOpacity: 0.8;
  --theme-color-noiseOpacity: 0;
  --theme-color-logoSize: 0;

  /*
    TODO(ds): Move into vanilla-extract theme
    This fn calculates avg bg color, later used for relative hover & active styles
  */
  --_bg-avg: color-mix(
    in oklab,
    color-mix(
      in oklab,
      var(--theme-color-bodyGradientStart),
      var(--theme-color-bodyGradientStop) 20%
    ),
    oklch(100% 0 0 / var(--theme-color-mainGradientStartOpacity))
  );
}

body {
  font-family: "DM Sans", sans-serif;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

a {
  text-decoration: none;
}

b,
strong {
  font-weight: bold;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  letter-spacing: -0.02em;
}
